.Main {
  background-color: #f8f9fa;
  height: 100vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: calc(20px + 2vmin);
  color: #343a40;
}

.wave {
  animation-name: wave-animation;
  animation-duration: 2.5s;
  animation-iteration-count: infinite;
  transform-origin: 70% 70%;
  display: inline-block;
}

.links {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.moon {
  height: 30vmin;
  pointer-events: none;
  border-radius: 100%;
  margin-right: 50px;
}

@media (prefers-reduced-motion: no-preference) {
  .moon {
    animation: moon-spin infinite 40s linear;
  }
}

@keyframes moon-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes wave-animation {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(14deg);
  }
  20% {
    transform: rotate(-8deg);
  }
  30% {
    transform: rotate(14deg);
  }
  40% {
    transform: rotate(-4deg);
  }
  50% {
    transform: rotate(10deg);
  }
  60% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@media only screen and (max-width: 600px) {
  .Main {
    flex-direction: column;
  }
  .moon {
    margin-right: 0px;
  }
}
