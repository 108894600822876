.Work {
  background-color: #e9ecef;
  height: 40vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  color: #343a40;
  border-radius: 20px 20px 0px 0px;
}

.title {
  font-size: calc(20px + 2vmin);
  margin: 20px;
}

@media only screen and (max-width: 600px) {
  .Work {
    height: 30vh;
  }
}
